import styled from 'styled-components';
import { InformationBox } from '@/components/shared/InformationBox';
import { InputForm } from '@/components/tools/VesselParticulars/shared/InputForm';

export const FieldPadding = styled.div`
  display: block;
  padding: 20px 20px 15px 20px;
`;

export const Title = styled.h2`
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 28px;
  color: ${props => props.theme.marineBlue};
`;

export const Information = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 15px;
  background-color: #FFF;
`;

export const Particulars = styled.div`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid black;
`;

// Override InputForm styles for use in Vessel Info and Company Particulars
export const StyledInputForm = styled(InputForm)`
  margin-top: 0px;
  padding: 10px 20px 20px;
  background-color: ${props => props.theme.white};
`;

// adjust padding around info box to align with pane padding
const StyledInfoBox = styled(InformationBox)`
  padding: 12px 20px 0px;
`;

export const ClaimWallWarning = () => (
  <StyledInfoBox paragraphs={['This claim is behind a claim wall']} />
);
