import client from '@/services/client';
import {
  ClaimsHandlerDashboardAssignment, Guid, SurveyorDashboardAssignment
} from '@/types';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';
import { useCurrentUserInfo } from './userInfoService';

const getClaimsHandlerUrl = (id: string, openStatus: boolean) => (
  `dashboardAssignments/${id}/cases?caseStatus=${openStatus ? 'open' : 'closed'}`
);

const getSurveyorUrl = (id: string, openStatus: boolean) => (
  `dashboardAssignments/surveyor/${id}/cases?openCases=${openStatus}`
);

const fetchFromUrl = async (url: string) => {
  const result = await client.get(url).then(res => res.data);
  return result;
};

const dashboardFetcher = async (url: string, openStatus: boolean, claimsHandlers: string[]) => {
  const requestUrls = claimsHandlers.map(id => getClaimsHandlerUrl(id, openStatus));
  // Will return array of arrays, which must then be made .flat()
  const result = await Promise.all(requestUrls.map(requestUrl => fetchFromUrl(requestUrl)));
  return result.flat();
};

export const useDashboardAssignments = (openStatus: boolean, claimsHandlers: Guid[]) => {
  const currentUser = useCurrentUserInfo();
  const claimsHandlerIds = useMemo(() => {
    // If none are selected, fall back to current user
    if (claimsHandlers.length < 1) return [currentUser.data?.id || ''];
    return claimsHandlers;
  }, [currentUser, claimsHandlers]);

  const { data, error } = useSWR<ClaimsHandlerDashboardAssignment[], AxiosError>(
    ['dashboardAssignments/filteredAssignments', openStatus, claimsHandlerIds],
    (url, status, ids) => dashboardFetcher(url, status, ids)
  );

  return { data, error };
};

export const useSurveyorDashboardAssignments = (openStatus: boolean) => {
  const currentUser = useCurrentUserInfo();
  const url = getSurveyorUrl(currentUser.data?.id || '', openStatus);
  return useSWR<SurveyorDashboardAssignment[], AxiosError>(url);
};
