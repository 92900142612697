import { useState, FunctionComponent } from 'react';
import { Form } from 'formik';
import {
  Certificates as CertificatesType, useCertificates, updateCertificates
} from '@/services/certificatesServices';
import {
  Loader, ButtonGroup, FormikWithPrompt as Formik
} from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { RelevantCertificates } from './edit/RelevantCertificates';
import { ClassRecommendations } from './edit/ClassRecommendations';
import { CertificateWithCheckbox } from './edit/CertificateWithCheckbox';
import { ISMCertificates } from './edit/ISMCertificates';
import { CertificateList } from './readonly/CertificateList';
import { Wrapper } from './Overview';
import { certificateSchema } from './certificateSchema';

interface CertificatesFormProps {
  data: CertificatesType;
  submit: (values: CertificatesType) => Promise<CertificatesType>;
}
const CertificatesForm: FunctionComponent<CertificatesFormProps> = ({ data, submit }) => (
  <Formik
    initialValues={data}
    validationSchema={certificateSchema}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const newValues = await submit(values);
      resetForm({ values: newValues });
      setSubmitting(false);
    }}>
    {({ setFieldValue, values, handleSubmit, isSubmitting, dirty }) => (
      <Form data-testid="certificates-form">
        <RelevantCertificates
          header="All relevant certificates valid (at time of occurrence)?"
          fieldName="invalidCertificates"
          onChange={newValue => setFieldValue('certificatesValid', newValue)}
          entries={values.invalidCertificates}
          value={values.certificatesValid}
        />
        <ClassRecommendations
          header="Class recommendations?"
          fieldName="classRecommendations"
          onChange={newValue => setFieldValue('hasClassRecommendations', newValue)}
          entries={values.classRecommendations}
          value={values.hasClassRecommendations}

        />
        <ISMCertificates
          header="ISM Certificates"
          fieldName="ismCertificates"
        />
        <Wrapper>
          <CertificateWithCheckbox
            onChange={newValue => setFieldValue('casualtyIsmReported', newValue)}
            value={values.casualtyIsmReported}
            header="Casualty ISM reported?"
            helpText="Please include report in Available information"
          />
        </Wrapper>
        <Wrapper>
          <CertificateWithCheckbox
            onChange={newValue => setFieldValue('priorRelatedIsmReports', newValue)}
            value={values.priorRelatedIsmReports}
            header="Prior related ISM reports?"
            helpText="Please include report in Available information"
          />
        </Wrapper>
        <ButtonGroup alignRight>
          <SaveButton
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            disabled={!dirty}
          />
        </ButtonGroup>
      </Form>
    )}
  </Formik>
);

const EditCertificates = () => {
  const caseId = useCaseIdFromUrl();
  const { data, error, mutate } = useCertificates();
  const [asyncError, setAsyncError] = useState<string | null>(null);

  if (error) throw error;
  if (asyncError) throw new Error(asyncError);

  if (!data) {
    return <Loader />;
  }

  const submit = async (values: CertificatesType) => {
    try {
      const newValues = await updateCertificates(caseId, values);
      void mutate(newValues);
      return newValues;
    } catch (errorMessage) {
      setAsyncError(errorMessage as string);
      throw errorMessage;
    }
  };

  return (
    <CertificatesForm data={data} submit={submit} />
  );
};

const ReadOnlyCertificates = () => {
  const { data, error } = useCertificates();

  if (error) {
    throw error;
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <CertificateList values={data} />
  );
};

interface CertificatesProps {
  readonly?: boolean;
}
export const Certificates: FunctionComponent<CertificatesProps> = ({ readonly = false }) => (
  readonly ? <ReadOnlyCertificates /> : <EditCertificates />
);
