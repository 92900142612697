import { CompanyParticular, Guid } from '@/types';
import { v4 as uuidv4 } from 'uuid';
import client from '@/services/client';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { AxiosError } from 'axios';
import useSWRWithSuspense from './swr-suspense';

export const companyParticularsBaseUrl = (caseId: string) => `/case/${caseId}/companyparticulars`;

export const useCompanyParticulars = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWRWithSuspense<CompanyParticular[], AxiosError>(companyParticularsBaseUrl(caseId));
  return swr;
};

export const addCompanyParticularAsync = async (caseId: string, newParticular: CompanyParticular) => {
  const newItem = { ...newParticular };
  newItem.id = uuidv4();
  const result = await client.post<CompanyParticular>(companyParticularsBaseUrl(caseId), newItem);
  return result.data;
};

export const updateCompanyParticularAsync = async (caseId: Guid, data: CompanyParticular) => {
  const url = `${companyParticularsBaseUrl(caseId)}/${data.id}`;
  await client.put<CompanyParticular>(url, data);
};

export const deleteCompanyParticularsAsync = (caseId: string, itemId: string) =>
  client.delete<null>(`${companyParticularsBaseUrl(caseId)}/${itemId}`);

export const restoreDeletedCompanyParticularAsync = async (caseId: string, itemId: string) => {
  const result = await client.post<CompanyParticular[]>(`${companyParticularsBaseUrl(caseId)}/${itemId}`);
  return result.data;
};
