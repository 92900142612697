import { roles } from '@/components/AppRouting/roles';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useCompanyParticulars } from '@/services/companyParticularsServices';
import {
  BoxButton, ButtonGroup, Divider, Loader
} from '@instech/components';
import { CompanyParticularsEditableView } from './pages/CompanyParticularsEditableView/CompanyParticularsEditableView';
import { CompanyParticularsReadOnlyView } from './pages/CompanyParticularsReadOnlyView';

export const CompanyParticularsTool = () => {
  const { data: particulars, error } = useCompanyParticulars();
  const { data: status } = useCaseStatus(true);
  const { close } = useModalContext();

  const role = useCurrentRole();
  const isReadOnly = role?.name === roles.claimHandler || status?.isClosed;

  if (error) {
    throw error;
  }
  if (!particulars) return <Loader />;

  return (
    <>
      {isReadOnly ? (
        <CompanyParticularsReadOnlyView particulars={particulars} />
      ) : (
        <CompanyParticularsEditableView particulars={particulars} />
      )}
      <Divider padding="0px" />
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Done</BoxButton>
      </ButtonGroup>
    </>
  );
};
export const openCompanyParticularsTool = {
  component: CompanyParticularsTool,
  options: {
    title: 'Company\'s particulars',
    size: 'small',
    footer: true // Deprecated
  },
  args: {}
};
