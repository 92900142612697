import * as Yup from 'yup';
import { Field } from '@/types';
import styled from 'styled-components';
import { AnyObject } from 'yup/lib/types';
import { FormikValues } from 'formik';
import { InputForm } from './InputForm';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

type Props<TModel> = {
  model: TModel & FormikValues;
  fields: Field[];
  onSubmit: (model: TModel) => Promise<void>;
  validationSchema: Yup.ObjectSchema<AnyObject>;
  onCancel: () => void;
  hideRestoreDialog?: () => void;
}

export const VesselInformationFormInline = <TModel, >({
  model,
  fields,
  onSubmit,
  validationSchema,
  hideRestoreDialog,
  onCancel
}: Props<TModel>) => {
  const submit = async (newModel: TModel) => {
    await onSubmit(newModel);
    if (hideRestoreDialog) {
      hideRestoreDialog();
    }
  };

  return (
    <Wrapper>
      <InputForm
        model={model}
        fields={fields}
        onSubmit={values => submit(values)}
        closeForm={onCancel}
        validationSchema={validationSchema}
      />
    </Wrapper>
  );
};
